import ko from 'knockout';
import { Observable, PureComputed } from 'knockout';
import template from './sessionExpiredOverlay.html';
import uploadManager from '@/Utils/uploadManager';
import redirectHelper from '@/Utils/redirectHelper';
import resourceHelper from '@/Utils/resourceHelper';

class SessionExpiredOverlay {

  public isVisible: Observable<boolean>;

  public uploads: any;
  public uploadsCount: Observable<number>;
  public allUploadsProgress: Observable<number>;
  public allUploadsComplete: Observable<boolean>;
  public uploadsFailed: Observable<boolean>;
  public failedUploadsCount: Observable<number>;
  public completedUploadsCount: Observable<number>;
  public progressLabel: string;

  constructor(params: any) {

    this.isVisible = params.isVisible;

    this.uploads = uploadManager.uploads;
    this.uploadsCount = uploadManager.uploadsCount;
    this.allUploadsProgress = uploadManager.allUploadsProgress;
    this.allUploadsComplete = uploadManager.allUploadsComplete;
    this.uploadsFailed = uploadManager.uploadsFailed;
    this.failedUploadsCount = uploadManager.failedUploadsCount;
    this.completedUploadsCount = uploadManager.completedUploadsCount;
    this.progressLabel = resourceHelper.getString('UploadProgress');
  }

  linkAriaDescription = ko.pureComputed<string>(() => {
    return this.uploadsFailed() ? [
      resourceHelper.getString('FailedToUploadItems', { itemCount: this.failedUploadsCount() }),
      resourceHelper.getString('SessionOverlayLinkHere')
    ].join(' ') : resourceHelper.getString('SessionOverlayLinkHere');
  });

  iconAriaDescription = ko.pureComputed<string>(() => {
    return this.isUploading() ?
      resourceHelper.getString('UploadingItems', { itemCount: this.uploadsCount(), percent: this.allUploadsProgress() }) :
      resourceHelper.getString('UploadedItems', { itemCount: this.completedUploadsCount() });
  });

  isFocusable: PureComputed = ko.pureComputed(() => {
    return this.isVisible();
  });

  isUploading: PureComputed = ko.pureComputed(() => {
    return this.allUploadsProgress() >= 0 && this.allUploadsProgress() < 100 && !this.allUploadsComplete();
  });


  uploadProgress: PureComputed = ko.pureComputed(() => {
    if (this.allUploadsComplete()) {
      return '100%';
    }

    return this.allUploadsProgress() + '%';
  });

  public continueInNewTabClick = (): void => {
    redirectHelper.saveRouteHash();

    // as the session is expired, we are redirected to login page
    window.open('/');
  };

  public continueHereClick = redirectHelper.tokenLoginRedirect;

}

export default { viewModel: SessionExpiredOverlay, template: template };
