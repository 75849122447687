import ko from 'knockout';
import template from './groupFilterArea.html';
import FilterDataModel from '../../Models/filterDataModel';

export function GroupFilterAreaViewModel(params) {

  const self = this;
  const mapForFilter = g => new FilterDataModel(g.groupId, g.groupDetails.groupName);
  const filterForGroupFiltered = g => self.filteredGroupsIds().includes(g.groupId);

  self.groupsEntities = params.groups;

  // initially we show all groups
  self.filteredGroupsIds = ko.observableArray(self.groupsEntities().map(g => g.groupId));
  self.filteredGroups = ko.pureComputed(() => self.groupsEntities().filter(g => filterForGroupFiltered(g)));
  self.groupsForFilter = ko.pureComputed(() => self.groupsEntities().map(i => mapForFilter(i)));
  self.selectedItemId = params.selectedItemId;
  self.index = ko.observable(null);

  self.selectGroup = group => self.selectedItemId(group.groupId);

  self.filteredGroupsIds.subscribe(function (newValue) {
    self.index(null);
  });
}

// The default export returns the component details object to register with KO
export default { viewModel: GroupFilterAreaViewModel, template: template };
