import template from './tabMenu.html';

/**
 * Component to create a horizontal tab menu. The menu items (params.items) should be in the format:
 *
 * {
 *     text: "Item Name",               - Required: The string displayed on the menu item
 *     isActive: ko.observable(false),  - Required: Flags if the menu item should be rendered as the active item
 *     name: "UniqueName"               - A unique internal name to refer to the menu item
 *     data: {                          - Data associated with the menu item
 *         valueA: 10,
 *         valueB: "Hello"
 *     }
 * },
 *
 * @example <tab-menu params="items: items, selectedItem: selectedItem"></tab-menu>
 * @param {Object} params
 */
export function TabMenuViewModel(params) {

  var self = this;
  var previousSelectedItem = params.selectedItem;

  // Properties shared with the view
  self.items = params.items;
  self.selectedItem = params.selectedItem;

  self.changeSelectedItem = function (item) {

    if (previousSelectedItem()) {
      previousSelectedItem().isActive(false);
    }

    item.isActive(true);
    self.selectedItem(item);

    previousSelectedItem(item);
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: TabMenuViewModel, template: template };
