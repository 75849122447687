import { Observable, ObservableArray } from 'knockout';
import * as ko from 'knockout';
import template from './requestList.html';

class RequestList {
  requestSummaries: ObservableArray;
  showCreateReportModal: Observable<boolean>;
  selectedRequestSummary!: Observable<any>;

  constructor(params: { requestSummaries: ObservableArray}) {
    this.requestSummaries = params.requestSummaries;
    this.showCreateReportModal = ko.observable(false);
    this.selectedRequestSummary = ko.observable();
  }

  onCreateReportClick = (selectedRequest: any): void => {
    this.selectedRequestSummary(selectedRequest);
    this.showCreateReportModal(!this.showCreateReportModal());
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: RequestList, template: template };
