import redirectHelper from '../../Utils/redirectHelper';

import template from './unknownErrorGettingUserData.html';

export function UnknownErrorGettingUserData() {
  const self = this;

  self.logout = function () {
    redirectHelper.logoutRedirect();
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: UnknownErrorGettingUserData, template: template };
