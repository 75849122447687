import template from './leftMenu.html';

/**
 * Component to create a left menu. The menu items (params.items) should be in the format:
 *
 * {
 *     text: "Item Name",               - Required: The string displayed on the menu item
 *     isActive: ko.observable(false),  - Required: Flags if the menu item should be rendered as the active item
 *     label: "Menu label"              - Required: A label for the menu for web accessibility purposes
 *     name: "UniqueName"               - A unique internal name to refer to the menu item
 *     isChild: false,                  - Flags is the menu item should be rendered as a child
 *     data: {                          - Data associated with the menu item
 *         valueA: 10,
 *         valueB: "Hello"
 *     }
 * },
 *
 * @example <left-menu params="items: items, selectedItem: selectedItem"></left-menu>
 * @param {Object} params
 */
export function LeftMenuViewModel(params) {

  var self = this;
  var previousSelectedItem = params.selectedItem();

  // Properties shared with the view
  self.items = addMissingPropertiesToItems(params.items);
  self.selectedItem = params.selectedItem;
  self.label = params.label;

  self.changeSelectedItem = function (item) {

    if (previousSelectedItem) {
      previousSelectedItem.isActive(false);
    }

    item.isActive(true);
    self.selectedItem(item);

    previousSelectedItem = item;
  };

  /**
   * Ensure each item has the properties required for the view bindings
   * @param {*} items
   * @return {*}
   */
  function addMissingPropertiesToItems(items) {

    var i;
    var item;

    for (i = 0; i < items.length; i++) {
      item = items[i];
      item.isChild = item.isChild || null;
    }

    return items;
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: LeftMenuViewModel, template: template };


