import { Observable } from 'knockout';
import { SortOption } from './sortOption';
import { BaseSortDropdownOptions } from './baseSortDropdownOptions';

import template from './baseSortDropdown.html';

class BaseSortDropdown {
  id: string;
  labelResourceKey: string;
  options: SortOption[];
  selectedSortValue: Observable<string>;

  constructor(params: { options: BaseSortDropdownOptions }) {
    this.id = params.options.id || 'sortBy';
    this.labelResourceKey = params.options.labelResourceKey || 'SortBy';
    this.options = params.options.options;
    this.selectedSortValue = params.options.selectedSortValue;
  }

  getSelectValueFunc(sortOption: SortOption): string {
    return `${sortOption.sortField}|${sortOption.sortOrder}`;
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: BaseSortDropdown, template: template };
