import { Observable, ObservableArray } from 'knockout';
import { DualListBoxOptions } from '../../../../Components/dualListBox/types/dualListBoxOptions';
import DualListBoxItem from '../../../../Components/dualListBox/types/DualListBoxItem';
import { DualListBoxHeaderSize } from '../../../../Components/dualListBox/types/DualListBoxHeaderSize';
import template from './usersTab.html';

class UsersTabViewModel {

  public pageMode: string;
  public isDataAvailable: Observable<boolean>;
  public dualListBoxOptions: DualListBoxOptions;
  private readonly availableUsers: ObservableArray<DualListBoxItem>;
  private readonly assignedUsers: ObservableArray<DualListBoxItem>;

  constructor(params: any) {
    this.pageMode = params.pageMode;
    this.isDataAvailable = params.isDataAvailable;

    this.availableUsers = params.availableUsers;
    this.assignedUsers = params.assignedUsers;
    this.dualListBoxOptions = this.getDualListBoxOptions();
  }

    private getDualListBoxOptions = (): DualListBoxOptions => {
      const headersSize: DualListBoxHeaderSize = DualListBoxHeaderSize.none;
      const dualListBoxLocalizedStrings: { [str: string]: string } = this.getDualListBoxLocalizedStrings();
      return new DualListBoxOptions(headersSize, this.availableUsers, this.assignedUsers, this.isDataAvailable, dualListBoxLocalizedStrings);
    }

    private getDualListBoxLocalizedStrings = (): { [str: string]: string } => {
      return {
        AvailableItemsTitleKey: 'GroupUserDuallistBoxAvailableUsers',
        AssignedItemsTitleKey: 'GroupUserDuallistBoxGroupMembers',
        FilterAreaTitleKey: 'GroupUserDuallistBoxFilterAreaTitleKey',
        CheckedItemTooltipKey: 'GroupPrimaryContactCheckboxTooltipKey',

        ResAriaLabelCheckedItemKey: 'GroupUserDuallistBoxPrimaryContact',
        ResAriaLabelAddKey: 'GroupUserDuallistBoxAddUserToGroup',
        ResAriaLabelRemoveKey: 'GroupUserDuallistBoxRemoveUserFromGroup',
        ResAriaLabelAddAllKey: 'GroupUserDuallistBoxAddAllUsersToGroup',
        ResAriaLabelRemoveAllKey: 'GroupUserDuallistBoxRemoveAllUsersFromGroup'
      };
    }
}

// The default export returns the component details object to register with KO
export default { viewModel: UsersTabViewModel, template: template };
