import ko from 'knockout';
import contextData from '../../../contextData';
import template from './misc.html';
import DiscussionMessageModel from '../../../Features/DiscussionMessaging/Models/discussionMessageModel';

export function MiscViewModel() {

  var self = this;

  self.contextData = contextData;
  self.modalVisible = ko.observable(false);

  self.now = new Date();

  // Values for the requiredBySummary binding
  self.twoDaysAgo = new Date();
  self.yesterday = new Date();
  self.today = new Date();
  self.tomorrow = new Date();
  self.twoDaysTime = new Date();

  self.twoDaysAgo.setDate(self.twoDaysAgo.getDate() - 2);
  self.yesterday.setDate(self.yesterday.getDate() - 1);
  self.tomorrow.setDate(self.tomorrow.getDate() + 1);
  self.twoDaysTime.setDate(self.twoDaysTime.getDate() + 2);

  // Values for the editableSelect binding
  self.observableEmptyObjectA = ko.observable({ });
  self.observableEmptyObjectB = ko.observable({ });
  self.observableValueOf1 = ko.observable({ value: '1' });

  // Values for the enterTriggersElementClick binding
  self.secondButtonClickFunc = () => {
    alert('Second button clicked!');
  };

  // Value for the showModal binding
  self.showModal = function () {
    self.modalVisible(true);
  };

  self.hideModal = function () {
    self.modalVisible(false);
  };

  // Values for the discussion messaging components
  self.localDiscussionMessage = new DiscussionMessageModel(
    'Joe Blogs',
    'I asked our external management company to obtain this footage.\n\nETA is 5 days.',
    true,
    new Date());

  self.externalDiscussionMessage = new DiscussionMessageModel(
    'Officer Smith',
    'Please send this footage ASAP Joe.',
    false,
    new Date());

  self.mockSendMessageFunc = message => {
    return new Promise(resolve => {
      setTimeout(() => {
        const newDiscussionMessage = new DiscussionMessageModel(
          'Joe Blogs',
          message,
          true,
          null);

        self.discussionMessages.unshift(newDiscussionMessage);
        resolve();
      }, 2000);
    });
  };

  self.discussionMessages = ko.observableArray([
    this.localDiscussionMessage,
    this.externalDiscussionMessage
  ]);

  // Values for the left menu component
  self.selectMenuItem = ko.observable();
  self.menuItem1Active = ko.observable();
  self.menuItem2Active = ko.observable();
  self.menuItem3Active = ko.observable();

  // Values for the selectTextInput component
  self.cameras = [
    { cameraId: 'efe59abd-d723-4a74-97cb-c1038d6b025e', cameraName: 'Front door camera 1' },
    { cameraId: '2be2d833-e6d8-4568-abc3-4d34badb3599', cameraName: 'Front door camera 2' }
  ];
  self.selectedValueForSelectOrText = ko.observable({ cameraId: '2be2d833-e6d8-4568-abc3-4d34badb3599' });
  self.newCameraSelectedItem = ko.observable({ cameraId: 'new-camera', cameraName: 'New camera' });

  // Values for the map bindings
  self.mapCenter = ko.observable({ latitude: 51.854109, longitude: -1.432483 });
  self.mapMarkerWaterlooStation = { latitude: 51.503336, longitude: -0.114422 };
  self.mapMarkerEmbankmentTube = { latitude: 51.507604, longitude: -0.122282 };
}

// The default export returns the component details object to register with KO
export default { viewModel: MiscViewModel, template: template };
