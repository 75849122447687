import { Observable } from 'knockout';
import template from './requestHeader.html';
import * as ko from 'knockout';

/** Component to display the request details header with action buttons */
class RequestHeader implements RequestDetailsHeaderParams {
  // Public properties
  request!: any;
  discussionMessageCount!: Observable<number>;
  isRejecting!: Observable<boolean>;
  rejectRequestFunc!: () => void;
  isSaving!: Observable<boolean>;
  saveRequestFunc!: () => void;
  isSubmitting!: Observable<boolean>;
  submitResponseFunc!: () => void;
  disableActionButtons!: Observable<boolean>;
  showCreateReportModal: Observable<boolean>;
  selectedRequestObservable: Observable<any>

  constructor(params: RequestDetailsHeaderParams) {
    Object.assign(this, params);
    this.showCreateReportModal = ko.observable(false);
    this.selectedRequestObservable = ko.observable(this.request);
  }

  onCreateReportClick = (): void => {
    this.showCreateReportModal(!this.showCreateReportModal());
  };
}

export interface RequestDetailsHeaderParams {
  request: any;
  discussionMessageCount: Observable<number>;
  isRejecting: Observable<boolean>;
  rejectRequestFunc: () => void;
  isSaving: Observable<boolean>;
  saveRequestFunc: () => void;
  isSubmitting: Observable<boolean>;
  submitResponseFunc: () => void;
  disableActionButtons: Observable<boolean>;
}

// The default export returns the component details object to register with KO
export default { viewModel: RequestHeader, template: template };
