import redirectHelper from '../../Utils/redirectHelper';
import contextData from '../../contextData';
import template from './userNavItem.html';

export function UserNavItemViewModel() {

  var self = this;

  self.userDisplayName = contextData.userData.displayName;
  self.versionNumber = contextData.portalSettings.versionNumber;

  self.logout = function () {
    redirectHelper.logoutRedirect();
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: UserNavItemViewModel, template: template };


