import redirectHelper from '../../Utils/redirectHelper';

import template from './businessDisabled.html';

export function BusinessDisabledViewModel() {
  const self = this;

  self.logout = function () {
    redirectHelper.logoutRedirect();
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: BusinessDisabledViewModel, template: template };
