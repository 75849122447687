import { Observable } from 'knockout';
import resourceHelper from '../../Utils/resourceHelper';
import { BaseSortDropdownOptions } from '../../BaseComponents/baseSortDropdown/baseSortDropdownOptions';
import { SortOption } from '../../BaseComponents/baseSortDropdown/sortOption';

import template from './camerasSortDropdown.html';

class CamerasSortDropdown {
  baseSortDropdownOptions: BaseSortDropdownOptions

  constructor(params: { selectedSortValue: Observable<string> }) {
    this.baseSortDropdownOptions = {
      options: CamerasSortDropdown.getSortOptions(),
      selectedSortValue: params.selectedSortValue
    };
  }

  static getSortOptions(): SortOption[] {
    return [
      {
        text: resourceHelper.getString('CameraName'),
        sortField: 'Name',
        sortOrder: 'Asc'
      },
      {
        text: resourceHelper.getString('DateAddedDesc'),
        sortField: 'AddedTimestamp',
        sortOrder: 'Desc'
      },
      {
        text: resourceHelper.getString('DateAddedAsc'),
        sortField: 'AddedTimestamp',
        sortOrder: 'Asc'
      }
    ];
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: CamerasSortDropdown, template: template };
