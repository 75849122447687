import ko from 'knockout';
import { Observable } from 'knockout';
import addressLookupRepository from '../../../../Repositories/addressLookupRepository';
import logger from '../../../../Utils/logger';
import template from './locationTab.html';
import constants from '../../../../constants';

const defaultZoomLevel = 17;

class LocationTabViewModel {
  private groupAddress: Observable<string>;
  private mapZoomLevel: Observable<number>;
  private searching: Observable<boolean>;
  private isLocationAssigned: Observable<boolean>;
  private coordinate: Observable<Array<number>>;
  private mapCenterCoordinate: Observable<Array<number>>;
  private savedMapDetails: any;
  private selectedItemIdObservable: Observable<string>;
  private selectedTabItemObservable: Observable<string>;
  private tabItemSub: any;
  private tabItemIdSub: any;

  constructor(params: any) {
    this.groupAddress = params.groupDetails.address;
    this.coordinate = params.groupDetails.coordinate;
    this.isLocationAssigned = params.groupDetails.isLocationAssigned;
    this.mapCenterCoordinate = params.initialCoordinate;
    this.mapZoomLevel = ko.observable(defaultZoomLevel);
    this.searching = ko.observable(false);
    this.savedMapDetails = {};
    this.selectedItemIdObservable = params.selectedItemIdObservable;
    this.selectedTabItemObservable = params.selectedTabItemObservable;

    if (this.selectedTabItemObservable) {
      this.tabItemSub = this.selectedTabItemObservable.subscribe(newTab => {
        this.selectedTabChangedHandler(newTab);
      });
    }

    if (this.selectedItemIdObservable) {
      this.tabItemIdSub = this.selectedItemIdObservable.subscribe(() => {
        this.savedMapDetails = {};
      });
    }
  }

  selectedTabChangedHandler(tabItem: any): void {
    if (tabItem.name === constants.tabNames.locationTab) {
      this.restoreMapDetails();
    } else {
      this.saveMapDetails();
    }
    this.tabItemSub?.dispose();
    this.tabItemIdSub?.dispose();
  }

  updateMarkerFromAddress(): void {

    // lookup address
    if (!this.groupAddress() || !this.groupAddress().trim()) {
      return;
    }

    logger.debug('looking up address for \'%s\'', this.groupAddress());

    this.searching(true);

    addressLookupRepository.lookupAddress(this.groupAddress(), false) // orderByDistanceToCenter is false
      .then(addressSearchResults => {

        this.searching(false);

        logger.debug('received %d results', addressSearchResults.length);

        if (!addressSearchResults.length) {
          logger.info('AddressSearchNoResultsMessage');
          return;
        }

        this.coordinate(addressSearchResults[0].coordinate);
        this.mapCenterCoordinate(addressSearchResults[0].coordinate);
        this.mapZoomLevel(defaultZoomLevel);

      })
      .catch(jqXhr => {

        this.searching(false);

        if (!jqXhr.errorHasBeenLogged) {
          const debugMessage = 'An unexpected error occurred while attempting retrieve address lookup results.';
          logger.error('UnhandledError', debugMessage, jqXhr);
        }
      });
  }

  // saving/restoring user's map changes in case the checkbox is re-enabled
  checkboxClickHandler(): true {
    if (!this.isLocationAssigned()) {
      this.saveMapDetails();
    } else {
      this.restoreMapDetails();
    }
    return true;
  }

  saveMapDetails(): void {
    this.savedMapDetails = {
      coordinate: this.coordinate(),
      groupAddress: this.groupAddress()
    };
    this.coordinate(this.savedMapDetails.coordinate);
    this.groupAddress(this.groupAddress());
    this.mapCenterCoordinate(this.savedMapDetails.coordinate);
  }

  restoreMapDetails(): void {
    if (this.savedMapDetails.groupAddress) {
      this.coordinate(this.savedMapDetails.coordinate);
      this.groupAddress(this.savedMapDetails.groupAddress);
      this.mapCenterCoordinate(this.savedMapDetails.coordinate);
    }
    this.savedMapDetails = {};
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: LocationTabViewModel, template: template };
