import * as ko from 'knockout';
import { Observable, PureComputed } from 'knockout';

import template from './newDiscussionMessage.html';

class NewDiscussionMessage {
  // Observable properties
  newMessage: Observable<string>;
  isSending: Observable<boolean>;
  sendButtonClass: PureComputed<string> = ko.pureComputed(() =>
    this.newMessage().length > 0 ? 'btn-primary' : 'btn-default' as string);

  // Standard Properties
  sendMessageFunc: (message: string) => Promise<void>;

  /**
   * @param {object} params Parameters for the component
   *  - sendMessageFunc - Required: Function to call to add a new message. Function must return a Promise.
   */
  constructor(params: { sendMessageFunc: (message: string) => Promise<void> }) {
    this.newMessage = ko.observable('');
    this.isSending = ko.observable(false);
    this.sendMessageFunc = params.sendMessageFunc;
  }

  onSendClicked = (): Promise<void> => {
    this.isSending(true);

    return this.sendMessageFunc(this.newMessage())
      .then(() => {
        this.newMessage('');
      })
      .catch(() => {
        console.warn('The Promise to save the new message was rejected');
      })
      .finally(() => {
        this.isSending(false);
      });
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: NewDiscussionMessage, template: template };


