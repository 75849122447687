import DiscussionMessageModel from '../Models/discussionMessageModel';
import { ObservableArray } from 'knockout';

import template from './discussionMessaging.html';

/** Component to display a list of discussion messages and an input to type a new message */
class DiscussionMessageViewModel {
  // Observable properties
  discussionMessages: ObservableArray<DiscussionMessageModel>;

  // Standard properties
  localMessageIconClassNames: string;
  externalMessageIconClassNames: string;
  sendMessageFunc: (message: string) => Promise<void>;

  constructor(params: DiscussionMessageParams) {
    this.discussionMessages = params.discussionMessages;
    this.localMessageIconClassNames = params.localMessageIconClassNames;
    this.externalMessageIconClassNames = params.externalMessageIconClassNames;
    this.sendMessageFunc = params.sendMessageFunc;
  }
}

export interface DiscussionMessageParams {
  discussionMessages: ObservableArray<DiscussionMessageModel>;

  /** String containing icon font class names for local messages */
  localMessageIconClassNames: string;

  /** String containing icon font class names for external messages */
  externalMessageIconClassNames: string;

  /** Function to call to add a new message. Function must return a Promise. */
  sendMessageFunc: (message: string) => Promise<void>;
}

// The default export returns the component details object to register with KO
export default { viewModel: DiscussionMessageViewModel, template: template };


