import ko from 'knockout';
import localStorageHelper from '@/Utils/localStorageHelper';
import redirectHelper from '@/Utils/redirectHelper';
import languageHelper from '@/Utils/languageHelper';
import resourceHelper from '@/Utils/resourceHelper';
import contextData from '@/contextData';
import template from './languageNavItem.html';

export function LanguageNavItemViewModel() {

  const self = this;

  self.availableLanguages = contextData.availableLanguages();
  self.currentLanguageResourceKey = languageHelper.getCurrentLanguageResourceKey();
  self.flagIcon = contextData.flagIcon;
  self.languageCode = contextData.languageCode();

  self.anchorAriaLabel = ko.pureComputed(() =>
    `${resourceHelper.getString(self.currentLanguageResourceKey)} ${resourceHelper.getString('SelectYourLanguage')}`);

  self.changeLanguage = function (languageData) {
    if (contextData.languageCode() === languageData.languageCode) {
      return;
    }

    localStorageHelper.setLanguageDetails(languageData.languageCode);

    redirectHelper.reload();
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: LanguageNavItemViewModel, template: template };


