import ko from 'knockout';
import template from './userFilterArea.html';
import FilterDataModel from '../../Models/filterDataModel';

export function UserFilterAreaViewModel(params) {

  const self = this;
  const mapForFilter = i => new FilterDataModel(i.personaId, [i.givenName, i.familyName], i.emailAddress);
  const filterForUserFiltered = u => self.filteredUsersIds().includes(u.personaId);

  self.usersEntities = params.users;
  // initially we show all users
  self.filteredUsersIds = ko.observableArray(self.usersEntities().map(u => u.personaId));
  self.filteredUsers = ko.pureComputed(() => self.usersEntities().filter(u => filterForUserFiltered(u)));
  self.usersForFilter = ko.pureComputed(() => self.usersEntities().map(i => mapForFilter(i)));
  self.selectedItemId = params.selectedItemId;
  self.index = ko.observable(null);

  self.selectUser = user => self.selectedItemId(user.personaId);

  self.filteredUsersIds.subscribe(function (newValue) {
    self.index(null);
  });
}

// The default export returns the component details object to register with KO
export default { viewModel: UserFilterAreaViewModel, template: template };
