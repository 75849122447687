import template from './createReportModal.html';
import { Observable } from 'knockout';
import * as ko from 'knockout';
import RequestModel from '@/Models/requestModel';
import RequestSummaryModel from '@/Models/requestSummaryModel';
import resourceHelper from '@/Utils/resourceHelper';
import { ValidationGroup } from '@/knockout.validation';
import reportRepository from '@/Repositories/reportRepository';
import logger from '@/Utils/logger';


class CreateReportModalViewModel implements CreateReportModalParams {
  request!: Observable<RequestModel | RequestSummaryModel>;
  isVisible!: Observable<boolean>;
  isGenerating!: Observable<boolean>;
  title!: Observable<string>;
  isValid!: Observable<boolean>;
  errors!: ValidationGroup;
  clientTimezoneOffset!: string;
  clientTimezoneName!: string;
  reportLinkElement!: HTMLAnchorElement | undefined;

  reportLink!: Observable<string>;
  reportFileName!: Observable<string>;
  fireClickOnDownloadReportElement!: Observable<boolean>;

  constructor(params: CreateReportModalParams) {
    Object.assign(this, params);
    this.title = ko.observable(this.request() ? this.getDefaultReportTitle(this.request().referenceNumber) : '')
      .extend({
        required: {
          message: resourceHelper.getString('TitleRequired')
        },
        maxLength: 200
      });

    this.clientTimezoneOffset = new Date().getTimezoneOffset().toString();
    this.clientTimezoneName = new Date().toTimeString().slice(9);

    this.isVisible.subscribe(() => {
      this.title(this.getDefaultReportTitle(this.request().referenceNumber));
    });

    this.isValid = ko.observable(true);
    this.isGenerating = ko.observable(false);

    this.errors = ko.validation.group([this.title]);
    this.errors.subscribe(newValue => this.isValid(newValue.length === 0));

    this.reportLink = ko.observable('');
    this.reportFileName = ko.observable('');
    this.fireClickOnDownloadReportElement = ko.observable(false).extend({ notify: 'always' });
  }

  getDefaultReportTitle(requestId: string): string {
    const currentDate = new Date();

    let month = `${currentDate.getMonth() + 1}`;
    month = month.length < 2 ? `0${month}` : month;

    let day = `${currentDate.getDate()}`;
    day = day.length < 2 ? `0${day}` : day;

    let hours = `${currentDate.getHours()}`;
    hours = hours.length < 2 ? `0${hours}` : hours;

    let minutes = `${currentDate.getMinutes()}`;
    minutes = minutes.length < 2 ? `0${minutes}` : minutes;

    let seconds = `${currentDate.getSeconds()}`;
    seconds = seconds.length < 2 ? `0${seconds}` : seconds;

    return `${requestId} ${resourceHelper.getString('Report')} - ${currentDate.getFullYear()}${month}${day} ${hours}${minutes}${seconds}`;
  }

  reportParams: ko.PureComputed<any> = ko.pureComputed(() => {
    return {
      title: this.title(),
      requestId: this.request().requestId,
      clientTimezoneOffset: this.clientTimezoneOffset,
      clientTimezoneName: this.clientTimezoneName
    };
  });

  downloadReport(data: any): void {
    const file = new Blob([data], { type: 'application/pdf' });
    const url = URL.createObjectURL(file);

    this.reportFileName(this.title());
    this.reportLink(url);

    this.fireClickOnDownloadReportElement(true);
  }

  openRequestReportURL(): void {
    this.isGenerating(true);

    reportRepository.getRequestReport(this.reportParams())
      .then(data => {
        this.downloadReport(data);
        this.isVisible(false);
      })
      .catch(jqXhr => {
        logger.error('UnhandledError', 'Failed to generate request report.', jqXhr);
      })
      .finally(() => {
        this.isGenerating(false);
      });
  }

  onCreateReportButtonClick = (): void => {
    if (this.isValid()) {
      this.openRequestReportURL();
    }
  }
}

export interface CreateReportModalParams {
  request: Observable<RequestModel | RequestSummaryModel>;
  isVisible: Observable<boolean>;
  isGenerating: Observable<boolean>;
}

// The default export returns the component details object to register with KO
export default { viewModel: CreateReportModalViewModel, template: template };
