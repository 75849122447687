import ko from 'knockout';
import template from './requestFormSwitcher.html';

/**
 * Component to either show the dynamic request details or the legacy fixed request details
 * @param {object} params
 * @constructor
 */
export function RequestFormSwitcher(params) {
  const self = this;

  self.params = params;
  const isDynamicResponseForm = !!self.params.request.dynamicResponseFormDefinition;

  self.requestComponentName = null;
  self.areRequestDetailsLoaded = ko.observable(false);

  self.requestComponentName = isDynamicResponseForm === true ? 'dynamic-request-details' : 'request-details';
  self.areRequestDetailsLoaded(true);
}

export default { viewModel: RequestFormSwitcher, template: template };
