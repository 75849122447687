import * as ko from 'knockout';
import { ObservableArray, Observable, PureComputed } from 'knockout';
import { FilterItemValueModel } from './filterItemValueModel';
import { FilterItemDto } from './filterItemDto';
import { FilterItemValueDto } from './filterItemValueDto';

export class FilterItemModel {
    public name: string;
    public position: number;
    public title: Observable<string>;
    public type: string;
    public isExpanded: Observable<boolean>;
    public isExpandedString: PureComputed<'true' | 'false'> = ko.pureComputed(
      () => this.isExpanded() ? 'true' : 'false');

  public collapseCss: string;
    public values: ObservableArray<FilterItemValueModel>;

    constructor(item: FilterItemDto, isExpanded: boolean, filterValueChangeHandler: () => void) {
      this.name = item.name;
      this.position = item.position;
      this.title = ko.observable(item.title);
      this.isExpanded = ko.observable(isExpanded);
      this.collapseCss = this.isExpanded() ? 'collapse in' : 'collapse';
      this.type = item.type;
      const values = this.mapToValues(item.values, filterValueChangeHandler);
      this.values = ko.observableArray(values);
    }

    private mapToValues = (items: FilterItemValueDto[], filterValueChangeHandler: any): FilterItemValueModel[] => {
      const values = items.map((value: FilterItemValueDto): FilterItemValueModel => {
        return new FilterItemValueModel(value, filterValueChangeHandler);
      });
      this.sort(values);
      return values;
    }

    public mergeValues = (
      existedValues: FilterItemValueModel[],
      receivedValues: FilterItemValueDto[],
      filterValueChangeHandler: () => void): void => {
      existedValues.forEach(existedValue => {
        existedValue.resetCounter();
      });
      const valuesToAdd = new Array<FilterItemValueModel>();
      receivedValues.forEach(receivedValue => {
        const existedValue = existedValues.find(value => {
          return receivedValue.value === value.value;
        });
        if (existedValue) {
          existedValue.setCounter(receivedValue.counter);
        } else {
          const newReceivedValue = new FilterItemValueModel(receivedValue, filterValueChangeHandler);
          valuesToAdd.push(newReceivedValue);
        }
      });

      const values = existedValues.concat(valuesToAdd);
      this.sort(values);
      this.values(values);
    }

    public syncCheckedValues = (valuesToSync: FilterItemValueModel[]): void => {
      valuesToSync.forEach(selectedValue => {
        const valueToUpdate = this.values().find(value => {
          return value.value === selectedValue.value;
        });
        if (valueToUpdate) {
          valueToUpdate.updateCheckedValueSilently(selectedValue.isChecked());
        }
      });
    }

    public resetCheckedValues = (): void => {
      this.values().forEach(value => value.resetChecked());
    }

    public toggleIsExpanded = (): void => {
      this.isExpanded(!this.isExpanded());
    }

    private sort = (values: FilterItemValueModel[]): void => {
      if (this.type === 'PositionedList') {
        values.sort(this.sortByPosition);
        return;
      }
      if (this.type === 'ListWithSortPriority') {
        values.sort(this.sortWithPositionPriority);
        return;
      }
      values.sort(this.sortByTitle);
    }

    private sortByPosition = (a: FilterItemValueModel, b: FilterItemValueModel): number => (a.position - b.position);

    private sortByTitle = (a: FilterItemValueModel, b: FilterItemValueModel): number => ((ko.unwrap(a.title) < ko.unwrap(b.title)) ?
      -1 :
      (ko.unwrap(a.title) > ko.unwrap(b.title)) ? 1 : 0);

    private sortWithPositionPriority = (a: FilterItemValueModel, b: FilterItemValueModel): number => {
      // values with negative positions go to the top of the list
      if (a.position < 0 && b.position < 0) {
        return a.position - b.position;
      }

      if (a.position < 0) {
        return -1;
      }

      if (b.position < 0) {
        return 1;
      }

      // values with >= 0 positions are sorted alphabetically
      return (ko.unwrap(a.title) < ko.unwrap(b.title) ?
        -1 :
        (ko.unwrap(a.title) > ko.unwrap(b.title)) ? 1 : 0);
    }

}
