import router from '../../Utils/router';
import redirectHelper from '../../Utils/redirectHelper';
import template from './helpNavItem.html';

export function HelpNavItemViewModel(params) {

  const self = this;

  self.showHelp = function () {
    const currentRoute = router.currentRoute();
    const helpId = params?.helpId || currentRoute.helpId;

    redirectHelper.openHelpWindow(helpId);
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: HelpNavItemViewModel, template: template };


