import ko from 'knockout';
import menuDataRepository from '../../Repositories/menuDataRepository';
import contextData from '../../contextData';
import template from './myAccountMenu.html';
import resourceHelper from '../../Utils/resourceHelper';

export function MyAccountMenuViewModel(params) {

  var self = this;

  if (!ko.isObservable(params.selectedPageComponentName)) {
    throw new Error('The required parameter selectedPageComponentName must be an observable');
  }

  if (!params.selectedPageComponentName()) {
    throw new Error('The parameter selectedPageComponentName must be an observable with a value');
  }

  // Observables shared with this component
  self.selectedPageComponentName = params.selectedPageComponentName;


  self.items = menuDataRepository.getMyAccountMenuData(contextData.userData.isBusinessAdminUser);
  self.selectedItem = ko.observable();

  self.selectedItem.subscribe(function (newItem) {
    self.selectedPageComponentName(newItem.data.pageComponentName);
  });

  self.label = resourceHelper.getString('MyAccount');

  setInitialActiveItem();

  /**
   * Use the selectedPageComponentName observable to lookup the item that should initally be active.
   */
  function setInitialActiveItem() {

    var currentItem;
    var i;

    for (i = 0; i < self.items.length; i++) {
      currentItem = self.items[i];

      if (currentItem.data.pageComponentName === self.selectedPageComponentName()) {
        currentItem.isActive(true);
        self.selectedItem(currentItem);
        return;
      }
    }
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: MyAccountMenuViewModel, template: template };


