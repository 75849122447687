import ko from 'knockout';
import template from './users.html';

export function UsersViewModel(routeParams) {

  const self = this;
  self.allUsers = ko.observableArray(routeParams.allUsers);
  self.totalCount = ko.pureComputed(() => self.allUsers().length);
  self.userDetailsValidationRules = routeParams.validationRules;

  self.selectedItemId = ko.observable(null);
  self.isItemSelected = ko.pureComputed(() => self.selectedItemId() !== null);

  // Is used for updating a single user summary in user list
  self.updateUser = userDetailsDto => {
    const usersArr = self.allUsers();

    const updateIndex = usersArr.findIndex(x => x.personaId === userDetailsDto.personaId);
    const updatedUserSummary = buildUserSummary(userDetailsDto, usersArr[updateIndex]);
    usersArr.splice(updateIndex, 1, updatedUserSummary);

    self.allUsers(usersArr);
  };

  // Is used for updating a user summary list when single user is removed
  self.deleteUser = userPersonaId => {
    const usersArr = self.allUsers();

    const deleteIndex = usersArr.findIndex(x => x.personaId === userPersonaId);
    usersArr.splice(deleteIndex, 1);

    self.allUsers(usersArr);
    self.selectedItemId(null);
  };

  function buildUserSummary(userDetailsDto, oldUserSummary) {
    return {
      personaId: userDetailsDto.personaId,
      givenName: userDetailsDto.givenName,
      familyName: userDetailsDto.familyName,
      emailAddress: userDetailsDto.emailAddress,
      isPreregistered: oldUserSummary.isPreregistered,
      isBusinessAdminUser: userDetailsDto.isBusinessAdminUser,
      isBusinessPrimaryContact: userDetailsDto.isBusinessPrimaryContact
    };
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: UsersViewModel, template: template };
