import ko from 'knockout';
import businessRepository from '../../../Repositories/businessRepository';
import localStorageHelper, { LocalStorageKeyType } from '../../../Utils/localStorageHelper';
import logger from '../../../Utils/logger';
import contextData from '../../../contextData';
import template from './cameras.html';

export function CamerasViewModel(routeParams) {

  var self = this;
  var usePersistedPage = routeParams.usePreviousViewSettings;
  var previousCameraId = routeParams.previousRoute.id;
  var initialCoordinate = contextData.portalSettings.defaultMapView.coordinate;
  var initialZoom = contextData.portalSettings.defaultMapView.zoom;

  if (contextData.userData.business.coordinate) {
    initialCoordinate = contextData.userData.business.coordinate;
    initialZoom = 17;
  }

  self.coordinate = ko.observable(initialCoordinate);
  self.zoom = ko.observable(initialZoom);

  self.mapCameras = ko.observableArray(routeParams.allCameras.results); // Used to show all cameras on the map
  self.cameras = ko.observableArray(); // Used to show a page cameras in the list

  self.loadingData = ko.observable(false);
  self.previousResultsExist = ko.observable(false);


  self.showLoading = ko.pureComputed(function () {
    return self.loadingData() && !self.previousResultsExist();
  }, self);

  self.disableResultsTable = ko.pureComputed(function () {
    return self.loadingData() && self.previousResultsExist();
  }, self);

  self.showNoResults = ko.pureComputed(function () {
    return !self.cameras().length && !self.loadingData();
  }, self);

  self.showResultsTable = ko.pureComputed(function () {
    return !!self.cameras().length && (!self.loadingData() || self.previousResultsExist());
  }, self);


  // Pagination observables
  self.currentPage = ko.observable(1);
  self.pageSize = ko.observable();
  self.total = ko.observable();

  // Set the default select input value for sort field and sort order which is pipe delimited
  self.selectedSortValue = ko.observable(contextData.camerasSort.sortField + '|' + contextData.camerasSort.sortOrder);

  if (usePersistedPage) {
    setPageFromStorage();
  }

  // Subscriptions setup after observables created to prevent the them firing during initial load
  self.selectedSortValue.subscribe(function (newValue) {

    // TODO: Why user context data here? Review using local storage helper and session value

    var sortValues = newValue.split('|');
    contextData.camerasSort.sortField = sortValues[0];
    contextData.camerasSort.sortOrder = sortValues[1];

    // When the sort value is changed, we need to reset the page number observable but don't want
    // to trigger the subscription or updateResults will be called twice.
    self.currentPage.disabled = true;
    self.currentPage(1);
    self.currentPage.disabled = false;

    updateResults();
  });

  self.currentPage.subscribe(function () {

    if (self.currentPage.disabled !== true) {
      updateResults();
    }
  });

  self.click = function (request) {
    request.isRequestLastAccessed(true);
    return true; // Return true so browser follows the href link to camera details screen
  };

  updateResults();

  function updateResults() {

    var sortValues = self.selectedSortValue().split('|');
    var sortField = sortValues[0];
    var sortOrder = sortValues[1];
    var page = self.currentPage();

    localStorageHelper.setSessionValue(LocalStorageKeyType.CamerasPage, page);

    self.loadingData(true);

    businessRepository.getCameras(sortField, sortOrder, page, previousCameraId)
      .then(function (pageResult) {

        self.loadingData(false);
        self.cameras(pageResult.results);

        self.pageSize(pageResult.pageSize);
        self.total(pageResult.total);

        if (pageResult.total) {
          self.previousResultsExist(true);
        } else {
          self.previousResultsExist(false);
        }

      })
      .catch(function (jqXhr) {

        self.loadingData(false);

        if (!jqXhr.errorHasBeenLogged) {
          var debugMessage = 'An unexpected error occurred while attempting retrieve the cameras.';
          logger.error('UnhandledError', debugMessage, jqXhr);
        }
      });
  }

  function setPageFromStorage() {

    var persistedPage = localStorageHelper.getSessionValue(LocalStorageKeyType.CamerasPage);

    if (!persistedPage) {
      return;
    }

    self.currentPage(persistedPage);
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: CamerasViewModel, template: template };
