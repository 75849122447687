import template from './authorisationError.html';
import redirectHelper from '../../Utils/redirectHelper';

export function AuthorisationErrorViewModel() {
  const self = this;

  self.logout = function () {
    redirectHelper.logoutRedirect();
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: AuthorisationErrorViewModel, template: template };
