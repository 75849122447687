import * as ko from 'knockout';
import { Observable } from 'knockout';
import template from './collapsedList.html';

class CollapsedList {
    public list: string[];
    public collapsedList: string[];
    public isCollapsible: boolean;
    public expandCount: number;
    public isCollapsed: Observable<boolean>;
    public collapsibleLinkStringKey: string;
    public noItemsStringKey: string;
    private shortListLength: number;
    private maxCollapsedListLength: number;

    constructor(private readonly options: any) {
      this.list = options.list;
      this.collapsibleLinkStringKey = options.collapsibleLinkStringKey || 'DefaultCollapsibleLinkStringKey';
      this.noItemsStringKey = options.noItemsStringKey || 'CollapsibleListNoItems';
      this.maxCollapsedListLength = options.maxCollapsedListLength;
      this.shortListLength = this.maxCollapsedListLength -1;

      this.list.sort();
      this.collapsedList = this.getCollapsedList(this.list);
      this.isCollapsible = this.list.length > this.maxCollapsedListLength;
      this.expandCount = this.list.length - this.shortListLength;
      this.isCollapsed = ko.observable(true);
    }

    expandList = (): void => this.isCollapsed(false);

    getCollapsedList = (list: string[]): string[] => {
      if (list.length > this.shortListLength) {
        return list.slice(0, this.shortListLength);
      }
      return list;
    }
}

// The default export returns the component details object to register with KO
export default { viewModel: CollapsedList, template: template };
