import ko from 'knockout';
import ValidationRulesService from '../../../../Validation/validationRulesService';
import currentUserRepository from '../../../../Repositories/currentUserRepository';
import logger from '../../../../Utils/logger';
import contextData from '../../../../contextData';
import template from './userDetails.html';

export function UserDetailsViewModel(params) {
  var self = this;

  var userData = contextData.userData;

  // ReSharper disable once JoinDeclarationAndInitializerJs
  var validationRulesService;
  var validationRules = params.validationRules;

  self.givenName = ko.observable(userData.givenName());
  self.familyName = ko.observable(userData.familyName());

  // TODO: Review if changing email address should be supported
  // self.emailAddress = ko.observable(userData.emailAddress());
  self.phoneNumber = ko.observable(userData.phoneNumber());

  self.formSubmitted = ko.observable(false);
  self.clientErrors = ko.validation.group(self, { deep: true });
  self.serverErrors = ko.observableArray();

  validationRulesService = new ValidationRulesService(self);
  validationRulesService.applyValidation(validationRules);

  self.saveChanges = function () {

    if (self.clientErrors().length > 0) {
      self.clientErrors.showAllMessages(true);
      return;
    }

    self.formSubmitted(true);

    var currentUserData = ko.toJS(self);

    currentUserRepository.updateUserData(currentUserData)
      .then(function (newUserData) {

        // Once the updated user details have been saved and the new UserModel returned from the repository
        // then update the fields on the context data.
        userData.givenName(newUserData.givenName());
        userData.familyName(newUserData.familyName());
        userData.displayName(newUserData.displayName());
        userData.phoneNumber(newUserData.phoneNumber());
        userData.emailAddress(newUserData.emailAddress());

        self.formSubmitted(false);

        logger.success('CurrentUserUpdatedSummary');
      })
      .catch(function (jqXhr) {
        self.formSubmitted(false);

        if (jqXhr.serverErrorMessages) {
          self.serverErrors(jqXhr.serverErrorMessages);
          return;
        }

        if (!jqXhr.errorHasBeenLogged) {
          logger.error(
            'UnhandledError',
            'An unexpected error occurred while attempting to update user details.', jqXhr);
        }
      });
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: UserDetailsViewModel, template: template };

