import { Observable } from 'knockout';
import resourceHelper from '../../Utils/resourceHelper';
import { BaseSortDropdownOptions } from '../../BaseComponents/baseSortDropdown/baseSortDropdownOptions';
import { SortOption } from '../../BaseComponents/baseSortDropdown/sortOption';

import template from './requestsSortDropdown.html';

class RequestsSortDropdown {
  baseSortDropdownOptions: BaseSortDropdownOptions

  constructor(params: { selectedSortValue: Observable<string> }) {
    this.baseSortDropdownOptions = {
      options: RequestsSortDropdown.getSortOptions(),
      selectedSortValue: params.selectedSortValue
    };
  }

  static getSortOptions(): SortOption[] {
    return [
      {
        text: resourceHelper.getString('DateCreatedDesc'),
        sortField: 'RequestTimestamp',
        sortOrder: 'Desc'
      },
      {
        text: resourceHelper.getString('DateCreatedAsc'),
        sortField: 'RequestTimestamp',
        sortOrder: 'Asc'
      },
      {
        text: resourceHelper.getString('RequiredByDateAsc'),
        sortField: 'RequiredByTimestamp',
        sortOrder: 'Asc'
      },
      {
        text: resourceHelper.getString('SortPriorityDesc'),
        sortField: 'RequestPriority',
        sortOrder: 'Desc'
      },
      {
        text: resourceHelper.getString('RequestorName'),
        sortField: 'InvestigatorName',
        sortOrder: 'Asc'
      }
    ];
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: RequestsSortDropdown, template: template };
