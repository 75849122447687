import { FilterItemValueDto } from './filterItemValueDto';

export class FilterItemDto {
    public name: string;
    public title: string;
    public type: string;
    public position: number;
    public values: FilterItemValueDto[];

    constructor(item: FilterItemDto) {
      this.name = item.name;
      this.title = item.title;
      this.type = item.type;
      this.position = item.position;
      this.values = item.values;
    }
}