import ko from 'knockout';
import ValidationRulesService from '../../../../Validation/validationRulesService';
import businessRepository from '../../../../Repositories/businessRepository';
import addressLookupRepository from '../../../../Repositories/addressLookupRepository';
import logger from '../../../../Utils/logger';
import contextData from '../../../../contextData';
import template from './businessDetails.html';

export function BusinessDetailsViewModel(params) {
  var self = this;
  var defaultZoomLevel = 17;

  var initialCoordinate = contextData.portalSettings.defaultMapView.coordinate;

  var businessData = contextData.userData.business;
  var validationRules = params.validationRules;

  if (businessData.coordinate) {
    initialCoordinate = businessData.coordinate;
  }

  self.businessId = businessData.businessId;
  self.businessName = ko.observable(businessData.businessName);
  self.businessAddress = ko.observable(businessData.businessAddress);
  self.coordinate = ko.observable(initialCoordinate);

  self.mapCenterCoordinate = ko.observable(initialCoordinate);
  self.mapZoomLevel = ko.observable(defaultZoomLevel);

  self.formSubmitted = ko.observable(false);
  self.clientErrors = ko.validation.group(self, { deep: true });
  self.serverErrors = ko.observableArray([]);

  self.searching = ko.observable(false);

  var validationRulesService = new ValidationRulesService(self);
  validationRulesService.applyValidation(validationRules);

  self.updateMarkerFromAddress = function () {

    // lookup address
    if (!self.businessAddress().length) {
      return;
    }

    logger.debug('looking up address for \'%s\'', self.businessAddress());

    self.searching(true);

    addressLookupRepository.lookupAddress(self.businessAddress(), false) // orderByDistanceToCenter is false
      .then(function (addressSearchResults) {

        self.searching(false);

        logger.debug('received %d results', addressSearchResults.length);

        if (!addressSearchResults.length) {
          logger.info('AddressSearchNoResultsMessage');
          return;
        }

        self.coordinate(addressSearchResults[0].coordinate);
        self.mapCenterCoordinate(addressSearchResults[0].coordinate);
        self.mapZoomLevel(defaultZoomLevel);

      })
      .catch(function (jqXhr) {

        self.searching(false);

        if (!jqXhr.errorHasBeenLogged) {
          var debugMessage = 'An unexpected error occurred while attempting retrieve address lookup results.';
          logger.error('UnhandledError', debugMessage, jqXhr);
        }
      });
  };

  self.saveChanges = function () {

    if (self.clientErrors().length > 0) {
      self.clientErrors.showAllMessages(true);
      return;
    }

    self.formSubmitted(true);

    var businessData = ko.toJS(self);

    businessRepository.updateBusinessData(businessData)
      .then(function (businessData) {

        self.formSubmitted(false);

        contextData.userData.business.businessName = businessData.businessName;
        contextData.userData.business.businessAddress = businessData.businessAddress;
        contextData.userData.business.coordinate = businessData.coordinate;

        logger.success('CurrentBusinessUpdatedSummary');
      })
      .catch(function (jqXhr) {
        self.formSubmitted(false);

        if (jqXhr.serverErrorMessages) {
          self.serverErrors(jqXhr.serverErrorMessages);
          return;
        }

        if (!jqXhr.errorHasBeenLogged) {
          logger.error(
            'UnhandledError',
            'An unexpected error occurred while attempting to update business details', jqXhr);
        }
      });
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: BusinessDetailsViewModel, template: template };

