import DiscussionMessageModel from '../Models/discussionMessageModel';

import template from './discussionMessage.html';

/** Component to display a single discussion message */
class DiscussionMessageViewModel {
  discussionMessage: DiscussionMessageModel;
  iconClassNames: string;

  constructor(params: DiscussionMessageParams) {
    this.discussionMessage = params.discussionMessage;

    this.iconClassNames = params.discussionMessage.isLocalSender ?
      params.localMessageIconClassNames :
      params.externalMessageIconClassNames;
  }
}

export interface DiscussionMessageParams {
  discussionMessage: DiscussionMessageModel;

  /** String containing icon font class names for local messages */
  localMessageIconClassNames: string;

  /** String containing icon font class names for external messages */
  externalMessageIconClassNames: string;
}

// The default export returns the component details object to register with KO
export default { viewModel: DiscussionMessageViewModel, template: template };