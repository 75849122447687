import ko from 'knockout';
import logger from '../../Utils/logger';
import template from './viewModelInfo.html';

export function ViewModelInfoViewModel(params) {

  const self = this;
  const errorMessage = 'The parameter \'viewModel\' hasn\'t been provided to the \'viewModelInfo\' component';

  if (params.viewModel) {
    self.viewModel = params.viewModel;
  } else {
    self.viewModel = null; // Set viewModel to prevent binding error
    logger.debug(errorMessage);
  }

  self.json = ko.pureComputed(() => ko.toJSON(params.viewModel, null, 2));
}

// The default export returns the component details object to register with KO
export default { viewModel: ViewModelInfoViewModel, template: template };
