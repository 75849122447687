import { ObservableArray } from 'knockout';
import template from './filterPanel.html';
import { FilterItemModel } from './Models/filterItemModel';

class FilterPanel {
    public filterItems: ObservableArray<FilterItemModel>;

    constructor(options: any) {
      this.filterItems = options.filterItems;
    }

}

// The default export returns the component details object to register with KO
export default { viewModel: FilterPanel, template: template };